<template>
  <div v-loading="loading" class="order-detail-container">
    <div class="buttonbc">
      <el-button @click="() => { $router.go(-1); }" size="small" class="back_btn">
        <img src="@/assets/back.png" alt="">
      </el-button>
    </div>
    <h2>开票明细</h2>
    <div class="cont">
      <div style="width:40%;">
        <p>
          <span>发票金额：</span>
          <span>￥{{ details.receipt_amount }}</span>
        </p>
        <p>
          <span>发票状态：</span>
          <span v-if="details.status === -1">已拒绝</span>
          <span v-if="details.status === 0">待审核</span>
          <span v-if="details.status === 1">已通过</span>
          <span v-if="details.status === 2">已经邮寄</span>
        </p>
        <p>
          <span>发票抬头：</span>
          <span>{{ details.receipt_title }}</span>
        </p>
        <p>
          <span>申请时间：</span>
          <span>{{ details.create_time | unixToDate }}</span>
        </p>
        <p>
          <span>联系电话：</span>
          <span>
            {{ details.receive_phone }}
          </span>
        </p>
        <!-- <p>
                   <span>物流公司：</span>
                   <span>{{details.logi_name}}</span>
                 </p>-->
      </div>
      <div style="width:40%;">
        <!-- <p>
                  <span>供应商（发票开具方）：</span>
                  <span>京东</span>
                </p> -->
        <p>
          <span>发票编号：</span>
          <span>{{ details.batch_sn }}</span>
        </p>
        <p>
          <span>发票类型：</span>
          <!-- <span>{{details.receipt_type === 'ELECTRO'? '增值税普通发票':'增值税专用发票'}}</span> -->
          <span>{{
    details.receipt_type === "ELECTRO"
      ? "电子普通发票"
      : details.receipt_type === "VATORDINARY"
        ? "增值税普通发票"
        : "增值税专用发票"
  }}</span>
        </p>
        <p>
          <span>收货人：</span>
          <span>{{ details.receive_name }}</span>
        </p>
        <p>
          <span>收取地址：</span>
          <span>{{ details.receive_addr }}</span>
        </p>
        <!-- <p>
                   <span>快递编号：</span>
                   <span>{{details.delivery_no}}</span>
                 </p>-->
      </div>
    </div>
    <div class="tables">
      <el-button type="primary" style="margin-bottom: 16px; margin-left: 10px;"
        @click="handleExportOrders">导出</el-button>
      <div v-if="this.is === '1'">
        <table cellspacing="0" cellpadding="0" border="1" class="el-table-yuepp">
          <!-- 表头开始 -->
          <thead style="width: 100%">
            <tr class="el-table-tr">
              <th colspan="1" class="el-table_3_column_1 el-table-column--selection  is-leaf"
                style="text-align: center; background-color: rgb(230, 236, 247);">
                <div class="cell">关联总订单号</div>
              </th>
              <th colspan="1" class="el-table_3_column_1 el-table-column--selection  is-leaf"
                style="text-align: center; background-color: rgb(230, 236, 247);">
                <div class="cell">订单号</div>
              </th>
              <th colspan="1" class="el-table_3_column_2 el-table-column--selection  is-leaf"
                style="text-align: center; background-color: rgb(230, 236, 247);">
                <div class="cell">完成时间</div>
              </th>
              <th colspan="1" class="el-table_3_column_3 el-table-column--selection  is-leaf"
                style="text-align: center; background-color: rgb(230, 236, 247);">
                <div class="cell">总价</div>
              </th>
              <th colspan="1" class="el-table_3_column_4 el-table-column--selection  is-leaf"
                style="text-align: center; background-color: rgb(230, 236, 247);">
                <div class="cell">可开具金额</div>
              </th>
              <th colspan="1" class="el-table_3_column_5 el-table-column--selection  is-leaf"
                style="text-align: center; background-color: rgb(230, 236, 247);">
                <div class="cell">商品名称</div>
              </th>
              <th colspan="1" class="el-table_3_column_6 el-table-column--selection  is-leaf"
                style="text-align: center; background-color: rgb(230, 236, 247);">
                <div class="cell">单价</div>
              </th>
              <th colspan="1" class="el-table_3_column_7 el-table-column--selection  is-leaf"
                style="text-align: center; background-color: rgb(230, 236, 247);">
                <div class="cell">数量</div>
              </th>
            </tr>
          </thead>
          <!-- 表头完 -->
          <!-- 表身 -->
          <tbody v-for="(item, index) of tableData" :key="index">
            <tr v-for="(itemI, index_I) of item.items_json" :key="index_I">
              <td v-if="!index_I" :rowspan="item.items_json.length">
                {{ item.trade_sn }}
              </td>
              <td v-if="!index_I" :rowspan="item.items_json.length">
                {{ item.sn }}
              </td>
              <td v-if="!index_I" :rowspan="item.items_json.length">
                {{ item.complete_time | unixToDate }}
              </td>
              <td v-if="!index_I" :rowspan="item.items_json.length">
                {{ item.order_price | unitPrice }}
              </td>
              <td v-if="!index_I" :rowspan="item.items_json.length">
                {{ item.order_price - item.order_refund_price | unitPrice }}
              </td>
              <td style="width: 28%;">{{ itemI.name }}</td>
              <td>{{ itemI.original_price }}</td>
              <td>{{ itemI.num }}</td>
            </tr>
          </tbody>
          <!-- 表身完 -->
        </table>
      </div>
      <el-table :header-cell-style="{ textAlign: 'center', backgroundColor: 'rgba(230, 236, 247, 1)' }"
        style="width: 100%;text-align: center;" v-if="this.is === '2'" :data="tableData" border>
        <el-table-column prop="trade_sn" label="关联总订单号"></el-table-column>
        <el-table-column prop="sn" label="订单号"></el-table-column>
        <el-table-column prop="create_time" label="完成时间" width="160">
          <template slot-scope="scope">
            {{ scope.row.create_time | unixToDate }}
          </template>
        </el-table-column>
        <el-table-column prop="service_name" label="服务名称"></el-table-column>
        <el-table-column prop="price" label="可开具金额">
          |
          <template slot-scope="scope">
            {{ scope.row.price | unitPrice }}
          </template>
        </el-table-column>
      </el-table>
      <!--      运费发票列表-->
      <el-table v-if="this.is === '3'"
        :header-cell-style="{ textAlign: 'center', backgroundColor: 'rgba(230, 236, 247, 1)' }" :data="tableData"
        border>
        <el-table-column prop="trade_sn" label="关联总订单号"></el-table-column>
        <el-table-column prop="create_time" label="完成时间" width="160">
          <template slot-scope="scope">
            {{ scope.row.create_time | unixToDate }}
          </template>
        </el-table-column>
        <el-table-column prop="platform_freight_price" label="运费金额">
          <template slot-scope="scope">
            {{ scope.row.platform_freight_price | unitPrice }}
          </template>
        </el-table-column>
        <el-table-column prop="platform_freight_price" label="可开具金额">
          <template slot-scope="scope">
            {{ scope.row.platform_freight_price | unitPrice }}
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination background slot="pagination" @size-change="handlePageSizeChange"
      @current-change="handlePageCurrentChange" :current-page="pageData.page_no" :page-sizes="MixinPageSizes"
      :page-size="pageData.page_size" :layout="MixinTableLayout" :total="pageData.data_total"></el-pagination>
    <div class="cont">
      <h2 style="margin-bottom: 0;">物流详情</h2>
      <template v-if="!details.logi_name && !details.logi_id">
        （暂无）
      </template>
      <template v-else>
        <el-steps direction="vertical" :active="0">
          <el-step v-for="(item, index) in logisticsData.data" :title="item.context" :description="item.time"
            :key="index"></el-step>
        </el-steps>
      </template>
    </div>
  </div>
  <!-- </div> -->
</template>

<script>
import * as API_goods from "@/api/goods";
import * as API_Account from "@/api/account";
import { handleDownload } from '@/utils';
import { Foundation } from "../../../ui-utils";
import { mobile } from '../../../ui-utils/RegExp';

export default {
  name: "Invoicemangerjiludetail",
  components: {},
  data () {
    return {
      is: "",
      id: "",
      details: {},
      paramsData: {
        page_no: 1,
        page_size: 20,
        history_id: '',
        type: ""
      },
      tableData: [],

      /** 列表loading状态 */
      loading: false,
      /** 订单详情数据 */
      orderDetail: {},

      /** 物流信息 */
      logisticsData: [],
      route: "",
      /* 分页数据 */
      pageData: {},
      orderstr: "",
      exportNum: 0,
      exportData: []
    };
  },
  mounted () {
    this.id = this.$route.query.id;
    this.is = this.$route.query.is;
    this.getInfo();
  },
  methods: {
    getInfo () {
      API_goods.getInfo(this.id).then(res => {
        this.details = res;
        this.orderstr = res.orderstr;
        this.getOrderList();
        this.GET_Express();
      });
    },
    /** 分页大小发生改变 */
    handlePageSizeChange (size) {
      this.paramsData.page_no = 1
      this.paramsData.page_size = size;
      this.getOrderList();
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange (page) {
      this.paramsData.page_no = page;
      this.getOrderList();
    },
    getOrderList () {
      // this.paramsData.orderstr = this.orderstr;
      this.paramsData.history_id = this.id;
      this.paramsData.type = this.is;
      API_goods.getOrderList(this.paramsData).then(res => {
        this.tableData = res.data;
        this.pageData['data_total'] = res.data_total
        this.pageData['page_no'] = res.page_no
        this.pageData['page_size'] = res.page_size
        if (this.is === '1') {
          this.tableData.map((item, index) => {
            this.tableData[index].items_json = JSON.parse(
              this.tableData[index].items_json
            );
          });
        }
      });
    },
    GET_Express () {
      if (this.details.logi_name && this.details.logi_id && this.details.delivery_no) {
        API_Account.getExpressDetail({
          id: this.details.logi_id, //快递公司
          num: this.details.delivery_no, //物流单号
          mobile: this.details.receive_phone//电话
        }).then(res => {
          this.logisticsData = res;
        });
      }
    },
    /** 导出发票订单 */
    handleExportOrders () {
      this.exportNum = 0
      this.exportData = []
      this.loading = true

      let params = {
        page_no: 1,
        page_size: 99999,
        type: this.paramsData.type,
        history_id: this.id
      };

      API_goods.getOrderList(params).then(res => {
        this.exportData = res.data
        this.exportOrdersHandle(this.exportData)
        this.loading = false
      })
    },
    exportOrdersHandle (response) {
      if (this.is !== '3') {
        let tHeaders = [
          '关联总订单号',
          '订单号',
          '订单完成时间',
          '总价',
          '可开具金额',
          '商品名称',
          '商品单价',
          '商品数量'
        ]
        let filterVals = [
          'trade_sn',
          'sn',
          'complete_time',
          'order_price',
          'order_refund_price',
          'name',
          'original_price',
          'num'
        ]
        let data = []
        response.forEach(item => {
          let tmp_obj = {}
          tmp_obj["trade_sn"] = item.trade_sn
          tmp_obj["sn"] = item.sn
          tmp_obj["complete_time"] = Foundation.unixToDate(item.complete_time, 'yyyy-MM-dd hh:mm:ss');
          tmp_obj["order_price"] = item.order_price
          tmp_obj["order_refund_price"] = item.order_price - item.order_refund_price
          item["items_json"] = JSON.parse(item["items_json"])
          if (item["items_json"].length > 1) {
            item["items_json"].forEach(item_1 => {
              let obj = {}
              obj["trade_sn"] = item.trade_sn
              obj["sn"] = item.sn
              obj["complete_time"] = Foundation.unixToDate(item.complete_time, 'yyyy-MM-dd hh:mm:ss');
              obj["order_price"] = item.order_price
              obj["order_refund_price"] = item.order_price - item.order_refund_price
              obj["name"] = item_1["name"].replaceAll('amp;', '').replaceAll(' ', '&nbsp;')
              obj["original_price"] = item_1["original_price"]
              obj["num"] = item_1["num"]
              data.push(obj)
            })
          } else {
            tmp_obj["name"] = item["items_json"][0]["name"]
            tmp_obj["original_price"] = item["items_json"][0]["original_price"]
            tmp_obj["num"] = item["items_json"][0]["num"]
            data.push(tmp_obj)
          }
        });
        handleDownload(data, tHeaders, filterVals, '商品发票');
      } else {
        let tHeaders = [
          '关联总订单号',
          '订单完成时间',
          '发票金额',
          '可开具金额',
        ]
        let filterVals = [
          'trade_sn',
          'complete_time',
          'freight_price',
          'platform_freight_price'
        ]
        let data = []
        response.forEach(item => {
          let tmp_obj = {}
          tmp_obj["trade_sn"] = item.trade_sn
          tmp_obj["complete_time"] = Foundation.unixToDate(item.complete_time, 'yyyy-MM-dd hh:mm:ss');
          tmp_obj["freight_price"] = item.platform_freight_price
          tmp_obj["platform_freight_price"] = item.platform_freight_price
          data.push(tmp_obj)
        });
        handleDownload(data, tHeaders, filterVals, '运费发票');
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.order-detail-container {
  background: #fff;
  position: relative;
  padding: 20px;
  border-radius: 5px;
}

.buttonbc {
  float: right;
  height: 40px;

  .back_btn {
    width: 36px;
    height: 36px;
    background-color: #1A43A9;
    border-radius: 50%;

    img {
      width: 10px;
      height: 20px;
      position: relative;
      top: -2px;
      left: -4px;
    }
  }
}

.cont {
  /* width: 1400px; */
  /* margin-left: 80px; */
  margin-left: 30px;
  padding-bottom: 20px;
  display: flex;
  align-items: center;
  margin-top: 10px;
}

/deep/ .is-leaf {
  /* border: 1px solid #000000 !important; */
  box-sizing: border-box;
  text-align: center;
  font-size: 16px;
}

/deep/ .el-table__row td {
  /* border: 1px solid #000000 !important; */
  text-align: center;
  font-size: 16px;
}

/deep/ .el-table--border {
  /*
    border: 1px solid #000000 !important; */
}

.zongji {
  width: 100%;
  font-size: 26px;
  text-align: right;
  padding-top: 30px;
}

.sendhuo {
  margin-top: 60px;
  float: right;
}

.goods-image {
  width: 50px;
  height: 50px;
}

.el-table-tr {
  width: 100%;
  background-color: rgb(230, 236, 247);
  text-align: center;
  color: #909399;
  font-weight: 500;
}

.el-table-tr th {
  border: 1px solid #dcdfe6;
  height: 50px;
}

.el-table_3_column_1 {
  flex: 4;
}

.el-table_3_column_2 {
  flex: 4;
}

.el-table_3_column_3 {
  flex: 2;
}

.el-table_3_column_4 {
  flex: 2;
}

.el-table_3_column_5 {
  flex: 4;
  width: 35%;
}

.el-table_3_column_6 {
  flex: 2;
}

.el-table_3_column_7 {
  flex: 2;
}

.el-table-yuepp {
  width: 100%;
  border: 1px solid #dcdfe6;
}

td {
  text-align: center;
  height: 60px;
  border: 1px solid #dcdfe6;
  font-size: 14px;
  color: #606266;
}

/deep/ .el-step__title.is-wait {
  color: #666;
}
</style>
